<template>
    <div class="feedback-page">
        <StackRouterHeaderBar
            :leftButtonHandler="onClickBack"
            :leftButton="headerIcon"
            :title="'약속 피드백'"
            :showTitle="true"
        />

        <div v-if="stage" class="feedback-survey-wrapper">
            <div class="feedback-survey-top">
                <div class="feedback-survey-title" v-html="survey[stage]"></div>
                <div v-if="stage === 2" class="feedback-survey-description">
                    *프라이버시 보호 정책에 따라 두분 모두 의향이 있다고 답했을 때 상호 간의 연락처가 전달 됩니다.
                </div>
                <div v-else-if="stage === 3" class="feedback-survey-description">
                    *스킨십, 성적인 농담, 원나잇 목적으로 보이는 언행 등
                </div>
                <div v-else class="feedback-survey-description">*상대방에게 공개되지 않으니 솔직하게 답변해주세요!</div>
                <!-- <div v-if="stage === 6" class="feedback-survey-description">(중복 선택 가능)</div> -->
            </div>
            <div v-if="stage === 6">
                <Keywords :keywords="getSurveyAnswers" @keywordSelected="answer_6 = $event" />
            </div>
            <div v-else-if="stage !== 7">
                <div class="feedback-survey-options" v-for="(opt, idx) in getSurveyAnswers" :key="idx">
                    <span
                        v-if="stage === 1 && opt.feedback_type === 'not_meet'"
                        @click="onClickNoMeeting(opt)"
                        class="feedback-survey-if-not-met"
                        >{{ opt.name }}</span
                    >
                    <div
                        v-else
                        @click="onClickItem(opt.id)"
                        class="feedback-survey-single-option"
                        :class="{ selected: checkSelected(opt.id) }"
                    >
                        {{ opt.name }}
                    </div>
                </div>
                <div
                    v-if="
                        (selectEtc && (stage === 3 || stage === 4)) ||
                        (answer_3_etc.length && stage === 3) ||
                        (answer_4_etc.length && stage === 4)
                    "
                    class="sub-question m-t-10"
                >
                    {{ stage === 3 ? '어떤 부분이 가볍게 느껴지셨나요?' : '어떤 부분이 매너 없게 느껴지셨나요?' }}
                    <div
                        class="feedback-survey-options etc"
                        v-for="(opt, idx) in getSurveyAdditionalAnswers"
                        :key="idx"
                    >
                        <div
                            @click="onClickItem(opt.id)"
                            class="feedback-survey-single-option"
                            :class="{ selected: checkSelected(opt.id) }"
                        >
                            {{ opt.name }}
                        </div>
                    </div>
                </div>
            </div>
            <textarea
                v-else
                class="feedback-survey-opinion"
                name=""
                id=""
                cols="30"
                rows="10"
                v-model="answer_7"
                placeholder="만남을 가지시면서 느꼈던 감정 혹은 상대방의 태도 등 자유롭게 적어주세요(선택)"
            ></textarea>
        </div>
        <BottomButton
            :disabled="disabled"
            v-if="stage > 5"
            :blackBtn="true"
            :label="buttonLabel"
            @click="onClickButton"
        />
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'
import userService from '@/services/user'
import Keywords from '@/routes/user-detail/components/Keywords'

export default {
    name: 'MeetingFeedback',
    components: {
        Keywords,
    },
    props: {
        person: {
            type: String,
            required: true,
        },
        message: {
            type: Object,
        },
        scheduleId: {
            type: Number,
        },
        targetId: {
            type: Number,
        },
    },
    data() {
        return {
            stage: 1,
            answer_1: [],
            answer_2: [],
            answer_3: [],
            answer_3_etc: [],
            answer_4: [],
            answer_4_etc: [],
            answer_5: [],
            answer_6: [],
            answer_7: '',
            answer_10: [],
            selectEtc: false,
        }
    },

    computed: {
        survey() {
            return {
                1: `${this.person}님과의 만남은 어땠나요?`,
                2: `앞으로 연락을 이어갈 의향이 있으신가요?`,
                3: `혹시 ${this.person}님은 가벼운 목적으로 보인 부분이 있었나요?`,
                4: `${this.person}님은 매너 있는 분이었나요?`,
                5: `${this.person}님을 실제로 만나보니 어떠셨나요?`,
                6: `${this.person}님을 가장 잘 묘사하는 키워드를 최대 3가지 골라주세요.`,
                7: `<b>${this.person}님에 대해서</b> 자유롭게 적어주세요`,
            }
        },
        getSurveyAnswers() {
            return this.$options.filters
                .feedback(this.stage)
                .filter(o => o.feedback_type !== 'how_was_light' && o.feedback_type !== 'how_was_manner')
        },
        getSurveyAdditionalAnswers() {
            return this.$options.filters
                .feedback(this.stage)
                .filter(o => o.feedback_type === 'how_was_light' || o.feedback_type === 'how_was_manner')
        },
        headerIcon() {
            if (this.stage === 1) {
                return 'close'
            } else {
                return 'back'
            }
        },
        buttonLabel() {
            if (this.stage === 7) {
                return '제출'
            } else {
                return '다음'
            }
        },
        checkSelected() {
            return idx => {
                // if (this.stage < 3) {
                //     return this[`answer_${this.stage}`] === idx
                // } else {
                //     if (this[`answer_${this.stage}`].includes(idx)) {
                //         console.log('comdcom')
                //         return true
                //     }
                //     return false
                // }
                if (this[`answer_${this.stage}`].includes(idx)) {
                    return true
                } else if ((this.stage === 3 || this.stage === 4) && this[`answer_${this.stage}_etc`].includes(idx)) {
                    return true
                }
                return false
            }
        },
        feedbackUse() {
            return [
                {
                    path: 'feedbackuse1',
                    title: '선호도 및 매칭율 높이기',
                },
                {
                    path: 'feedbackuse2',
                    title: '선호도 및 매칭율 높이기',
                },
            ]
        },
        disabled() {
            if (this.stage === 7) return false
            return this[`answer_${this.stage}`].length ? false : true
        },
        userId() {
            return this.$store.getters.me.id
        },
    },

    methods: {
        async onClickItem(idx) {
            if (this.stage === 2 && idx === 28) {
                const agree = await this.$modal.basic({
                    body:
                        '상대방도 연락할 의향이 있다고 답한 경우에 연락처를 전달드립니다. (연락처를 못 받으시면 상대방이 애프터를 수락하지 않은 것입니다.)',
                    buttons: [
                        {
                            label: 'CONFIRM',
                        },
                    ],
                })

                if (agree !== 0) {
                    return
                }
            }

            if (this[`answer_${this.stage}`].includes(idx)) {
                if (this.stage !== 6) {
                    this[`answer_${this.stage}`] = []

                    if ((this.stage === 3 && idx === 30) || (this.stage === 4 && idx === 38)) {
                        this.selectEtc = false
                    }
                } else {
                    const i = this[`answer_${this.stage}`].indexOf(idx)
                    this[`answer_${this.stage}`].splice(i, 1)
                }
            } else {
                if (
                    this[`answer_${this.stage}`].length &&
                    ![32, 33, 34, 35, 39, 40, 41, 42, 43, 44, 45, 46].includes(idx)
                ) {
                    this[`answer_${this.stage}`] = []
                    if (this.stage === 3 || this.stage === 4) {
                        this[`answer_${this.stage}_etc`] = []
                    }
                } else if ([32, 33, 34, 35, 39, 40, 41, 42, 43, 44, 45, 46].includes(idx)) {
                    this[`answer_${this.stage}_etc`] = []
                }

                if (this.stage === 3 || this.stage === 4) {
                    if (idx === 30 || idx === 38) {
                        this.selectEtc = !this.selectEtc
                    }
                    if ([32, 33, 34, 35, 39, 40, 41, 42, 43, 44, 45, 46].includes(idx)) {
                        this[`answer_${this.stage}_etc`].push(idx)
                    } else {
                        this[`answer_${this.stage}`].push(idx)
                    }
                } else {
                    this[`answer_${this.stage}`].push(idx)
                }
            }

            if (this.stage === 3 && this.answer_3[0] === 30) {
                if (this.answer_3_etc.length) {
                    this.selectEtc = false
                    this.stage++
                }
            } else if (this.stage === 4 && this.answer_4[0] === 38) {
                if (this.answer_4_etc.length) {
                    this.selectEtc = false
                    this.stage++
                }
            } else if (this.stage < 6 && this[`answer_${this.stage}`].length) {
                this.selectEtc = false
                this.stage++
            }
        },
        async onClickButton() {
            if (this.stage < 7) {
                this.stage++
            } else {
                this.submit()
                this.$router.push({
                    name: 'ModalFeedbackEvent',
                })
            }
        },
        async onClickBack() {
            if (this.stage > 1) {
                this.stage--
            } else {
                const idx = await this.$modal.basic({
                    body: '피드백을 그만두시겠습니까?',
                    buttons: [
                        {
                            label: '아니오',
                            class: 'btn-default',
                        },
                        {
                            label: '네',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx) {
                    this.$stackRouter.pop()
                }
            }
        },
        async submit() {
            const payload = {
                schedule_id: this.scheduleId,
                message_id: this.message.id,
                target_id: this.targetId,
                answer_1: this.answer_1,
                answer_2: this.answer_2,
                answer_3: this.answer_3,
                answer_3_etc: this.answer_3_etc,
                answer_4: this.answer_4,
                answer_4_etc: this.answer_4_etc,
                answer_5: this.answer_5,
                answer_6: this.answer_6,
                answer_7: this.answer_7,
                answer_10: this.answer_10,
            }
            const res = await scheduleService.mannerFeedbacks(payload)
            if (res.get_invite_point) {
                this.$toast.success('만남 피드백 제출 완료! 친구 초대 포인트 20만원도 지급되었어요 :)')
                const { bag } = await userService.items()
                this.$store.commit('setItems', bag.item)
            } else {
                this.$toast.success('만남 피드백이 제출되었어요')
            }
            this.$store.dispatch('loadChat', { chatId: this.$store.getters.chat.id, force: true })
            this.$stackRouter.pop()
        },
        async onClickNoMeeting(opt) {
            // this[`answer_${this.stage}`].push(opt.id)
            const nomeeting = await this.$modal.custom({
                component: 'ModalVerticalButtons',
                options: {
                    showCloseButton: true,
                    buttons: [
                        {
                            label: '약속 취소',
                        },
                        {
                            label: '일정 변경',
                        },
                    ],
                    title: '어떤 이유로 만나지 않으셨나요?',
                },
            })
            if (nomeeting) {
                this.answer_10 = [21]
                this.submit()
            } else if (nomeeting === 0) {
                this.answer_10 = [20]
                this.submit()
            } else {
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.feedback-page {
    .feedback-survey-wrapper {
        padding: 16px;
        .feedback-survey-top {
            text-align: center;
            margin-bottom: 24px;
            .feedback-survey-title {
                font-size: 18px;
                line-height: 28px;
                color: #111111;
            }
            .feedback-survey-description {
                font-size: 12px;
                line-height: 20px;
            }
        }

        .sub-question {
            float: right;
        }

        .feedback-survey-options {
            &.etc {
                float: right;
                width: 70%;
            }

            .feedback-survey-single-option {
                width: 100%;
                height: 48px;
                border: 1px solid #b9bdc1;
                border-radius: 8px;
                margin: 8px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                line-height: 26px;
                color: #111111;
                padding: 10px;
            }
            .selected {
                color: #3da1ff;
                border: 1px solid #3da1ff;
            }
        }
        .feedback-survey-if-not-met {
            color: #3da1ff;
            font-size: 14px;
            line-height: 22px;
            text-decoration-line: underline;
        }
        .feedback-survey-opinion {
            border: 1px solid #ced5d9;
            border-radius: 8px;
            margin: 0;
            width: 100%;
            padding: 12px;
            font-size: 14px;
            line-height: 24px;
        }
    }
}
</style>
